<template>
  <div class="auth-wrapper bg-light-info auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Forgot Password v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <b-img
            :src="appLogoFull"
            height="70"
          />
        </b-link>

        <b-card-title class="mb-1">
          {{ $t('login.forgotPassword') }} 🔒
        </b-card-title>
        <b-card-text class="mb-2 text-info">
          {{ $t('login.registerUsername') }} <span class="text-danger">(*)</span>
        </b-card-text>

        <!-- form -->
        <validation-observer
          ref="refFormObserver"
          #default="{ invalid }"
        >
          <b-form
            class="auth-forgot-password-form mt-2"
            @submit.prevent="validationForm"
          >
            <!-- Username -->
            <b-form-group
              :label="$t('login.Username')"
              label-for="forgot-password-username"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('login.Username')"
                rules="required|min:2"
              >
                <b-form-input
                  id="forgot-password-username"
                  v-model="usernameToSent"
                  :state="errors.length > 2 ? false:null"
                  name="forgot-password-username"
                  :formatter="upperCaseFormatter"
                  :placeholder="$t('login.usernamePlaceholder')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              block
              type="submit"
              :disabled="invalid"
            >
              {{ $t('login.continue') }}
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <b-link :to="{name:'auth-login-v1'}">
            <feather-icon icon="ChevronLeftIcon" />
            {{ $t('login.backToLogin') }}
          </b-link>
        </b-card-text>
      </b-card>
      <!-- /Forgot Password v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BLink, BCardText, BCardTitle, BFormGroup, BFormInput, BForm, BButton, BImg,
} from 'bootstrap-vue'
import { ref, onUnmounted, computed } from '@vue/composition-api'

import { useRouter } from '@/@core/utils/utils'
import store from '@/store'

import formValidation from '@core/comp-functions/forms/form-validation'
import { upperCaseFormatter } from '@core/comp-functions/forms/formatter-input'

import useToast from '@useToast'
import { required, email } from '@validations'
import { $themeConfig } from '@themeConfig'

import authStoreModule from './authStoreModule'

export default {
  components: {
    BCard,
    BImg,
    BLink,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    ValidationProvider,
    ValidationObserver,
  },
  setup() {
    const { appLogoFull: defaultLogo } = $themeConfig.app
    const appLogoFull = computed(() => store.getters['app/getLogo']).value || defaultLogo

    const AUTH_APP_STORE_MODULE_NAME = 'app-auth'

    // Register module
    if (!store.hasModule(AUTH_APP_STORE_MODULE_NAME)) {
      store.registerModule(AUTH_APP_STORE_MODULE_NAME, authStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      // if (store.hasModule(AUTH_APP_STORE_MODULE_NAME)) {
      //   store.unregisterModule(AUTH_APP_STORE_MODULE_NAME)
      // }
    })
    const userEmail = ref(null)
    const usernameToSent = ref(null)

    const { toastSuccess, toastError } = useToast()
    const { router } = useRouter()

    const { refFormObserver } = formValidation()

    // Methods
    function validationForm() {
      this.$bvModal.show('modal-api-loading')
      this.$refs.refFormObserver.validate()
        .then(success => {
          if (success) {
            store.dispatch('app-auth/forgotPasswordByUsername', { username: usernameToSent.value.toLowerCase() })
              .then(() => {
                toastSuccess({
                  title: 'messagesList.success',
                  content: 'messagesList.auth.emailSent',
                })
                router.push({ name: 'auth-forgot-password-sent' })
              })
              .catch(error => {
                toastError({
                  title: 'messagesList.error',
                  content: this.$t(`messagesList.auth.${error?.response?.data?.message || error.message || error}`),
                })
              })
              .finally(() => {
                this.$bvModal.hide('modal-api-loading')
              })
          }
        })
    }

    return {
      userEmail,
      usernameToSent,

      // validation
      required,
      email,

      refFormObserver,

      // methods
      validationForm,
      upperCaseFormatter,
      appLogoFull,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
